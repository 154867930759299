import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import axios from 'axios';

export const generatePresignedURL = async (key, caseId) => {
  try {
    const s3Client = new S3Client({
      region: process.env.REACT_APP_API_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_API_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_API_SECRET_KEY,
      },
    });

    const command = new GetObjectCommand({
      Bucket: `${process.env.REACT_APP_API_BUCKET_NAME}${caseId}`,
      Key: key,
    });

    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    return null;
  }
};

export const handleGenerateFile = async (caseId) => {
  const key = 'summaries/Summary_375605039.txt';
  const apiURL = await generatePresignedURL(key, caseId);

  const response = await axios(apiURL);
  const apiResponse = response?.data;

  const value = apiResponse.split("value='");
    if (value.length > 1) {
        return value[1].split("')")[0];
    }
    return null;
};
