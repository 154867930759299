import React from 'react';
import CaseSearchFilter from '../../Shared/CaseSearchFilter';
import { Link } from 'react-router-dom';
import { ReactComponent as Eye } from '@icons/eye-black.svg';
import { ReactComponent as Trashcan } from '@icons/trashcan.svg';
import './MyCasesTable.css';

const MyCasesTable = ({ cases, onSelectCase }) => {
  const launchConfirmDeleteModal = (e) => {
    e.preventDefault();
    console.log('launchConfirmDeleteModal');
  };

  return (
    <div className="cases-index-table">
      <div className="cases-index-table-header">
        <h3>Cases</h3>
        <div className="cases-index-table-header-buttons">
          <CaseSearchFilter />
        </div>
      </div>
      <ul>
        <li className="cases-index-table-heading">
          <p>Case Name</p>
          <p>Full Number</p>
          <p>Team Name</p>
          <p>Latest Entry</p>
          <p>Status</p>
          <p>Action</p>
        </li>
        {cases && cases.length > 0 ? (
          cases.map((caseItem, index) => (
            <li key={index} className="cases-index-table-row">
              <p>{caseItem?.caseName}</p>
              <p>{caseItem?.caseDetails?.caseNumber}</p>
              <p>My Team</p>
              <p>{caseItem?.caseDetails?.dateFiled}</p>
              <div
                className={`cases-index-table-row-status ${
                  caseItem?.status == 'open' ? 'open' : 'closed'
                }`}
              >
                <p>{caseItem?.status}</p>
              </div>
              <div className="cases-list-actions-container">
                <Link
                  className="hover-cursor"
                  onClick={() => onSelectCase(caseItem)}
                  to={`/dashboard/cases/${caseItem.caseId}/overview`}
                >
                  <Eye />
                </Link>
                <Trashcan
                  className="hover-cursor"
                  onClick={launchConfirmDeleteModal}
                />
              </div>
            </li>
          ))
        ) : (
          <p className="empty-message text-center mt-3">
            No relevant cases records were found.
          </p>
        )}
      </ul>
    </div>
  );
};

export default MyCasesTable;
