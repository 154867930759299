import React, { useEffect, useState } from 'react';
import fileIcon from "@icons/file-with-background.svg";
import CustomButton from '@Shared/Button/Button';
import addIcon from "@icons/eye-icon.svg";
import actionIcon from '@icons/more-line.svg';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GrDocumentDownload, GrDocumentUpload } from "react-icons/gr";
import summarizeIcon from "@icons/add-summarize.svg";
import './DocumentItem.css';
import CollapsibleCard from '@Shared/CollapsibleCard/CollapsibleCard';
import TextBlock from '../../../Shared/TextBlock/TextBlock';
import ActionMenu from '@Shared/ActionMenu/ActionMenu';
import { handleGenerateFile } from '@lib/utils/awsSdkHelper';
import { getDocketSummaryUrl } from '@lib/apis/pacer-api';
import { useSelector } from 'react-redux';

const DocumentItem = ({ 
    index,
    title,
    description,
    summaryS3Url,
    role,
    uploadFileStatus,
    documentExists,
    docketEntryId,
    caseId
 }) => {
    const [isSummaryVisible, setIsSummaryVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSummarizeNeeded, setIsSummarizeNeeded] = useState(true);
    const [isAvailable, setIsAvailable] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [summary, setSummary] = useState('');
    const user = useSelector((state) => state.user);
    const {cognito_user_id: userId} = user;

    const handleSummarizeClick = async () => {
        if (isSummarizeNeeded) {
            setIsLoading(true);
            try {
                const response = await getDocketSummaryUrl(caseId, userId, docketEntryId);
                const apiResponse = response?.data;
                let value = apiResponse?.summary?.split("value='");
                    if (value?.length > 1) {
                        value =  value[1].split("')")[0];
                    }
                if (value) {
                    setIsSummarizeNeeded(false);
                    setSummary(value);
                    setIsLoading(false);
                    setIsSummaryVisible(!isSummaryVisible);
                } else if (response?.success == false) {
                    if (response?.isNotAvailable) {
                        setIsLoading(false);
                        setIsAvailable(false)
                    } else {
                        setIsLoading(false);
                       
                    }
                }
            } catch (error) {
                console.error('Error while summarizing:', error);
                setIsLoading(false);
            }
        } else if (summary) {
                setIsSummaryVisible(!isSummaryVisible);
            } else {
                setIsLoading(true);
                try {
                    const summaryResponse = await handleGenerateFile(caseId);
                    setSummary(summaryResponse);
                    setIsSummaryVisible(!isSummaryVisible);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Failed to get summary:', error);
                    setIsLoading(false);
                }
            }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("Selected file:", file.name);
        }
    };

    const menuItems = uploadFileStatus
        ? [
            {
                label: 'Download document',
                labelColor: "#344054",
                icon: <GrDocumentDownload />,
                onClick: () => console.log("Download clicked"),
            },
            {
                label: 'Delete document',
                labelColor: "#F2295B",
                icon: <FaRegTrashAlt style={{ color: "red" }} />,
                onClick: () => console.log("Delete clicked"),
            }
        ]
        : [
            {
                label: 'Load from PACER',
                labelColor: "#344054",
                onClick: () => console.log("Load clicked"),
            },
            {
                label: 'Upload from local',
                labelColor: "#344054",
                icon: <GrDocumentUpload />,
                onClick: () => document.getElementById('fileInput').click(),
            }
        ];

    useEffect(()=>{
        setIsSummarizeNeeded(!summaryS3Url) 
    }, [summaryS3Url ])

    return (
        <div key={index}>
            {role === "court" && !documentExists  ? (
                <TextBlock text={description} />
            ) : (
                <>
                <div className="document-item-card-file-container">
                    <div className='document-file-left-content'>
                        <img src={fileIcon} alt="no file" className='document-item-card-file-icon' />
                        <div className="document-item-card-text">{title}</div>
                    </div>
                    <div className='document-file-left-content'>
                        {isAvailable ? (
                                <CustomButton className={`document-file-summarize-button ${isSummarizeNeeded ? "summarize" : ""}`}
                                    onClick={handleSummarizeClick}>
                                    <img src={isLoading ? addIcon : (isSummarizeNeeded ? summarizeIcon : addIcon)} alt="" />
                                    <span>{isLoading ? "Loading..." : isSummarizeNeeded ? "Summarize" : "Summary"}</span>
                                </CustomButton>
                            ) : (
                                <CustomButton className={`document-file-summarize-button summarize-unavailable`}>Unavailable</CustomButton>
                            )}
                        <ActionMenu icon={actionIcon} items={menuItems} />
                    </div>
                </div>
                <div className='document-file-description-content'>
                    <p>{description}</p>
                </div>
                </>
            )}
            {isSummaryVisible && summary && <CollapsibleCard title={"Summary"} content={summary} />}
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
};

export default DocumentItem;
