import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Button from '../../Button/Button';
import topRIghtImage from './../../../../assets/images/atticus/meetAtticus/summarize-top-image.svg';
import addIcon from '@icons/add-summarize.svg';
import fileIcon from '@icons/file-with-background.svg';
import './DemoSlider.css';

const DemoSlider = ({ data }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {data?.map((demo, index) => (
          <div key={index} className="demo-slide-container">
            <div className="demo-sub-container">
              <div className="demo-slider-main-card">
                <img
                  src={topRIghtImage}
                  alt="Top Right Decor"
                  className="demo-top-right-image"
                />
                <div className="demo-first-sub-card">
                  <Button className="demo-summarize-button">
                    <img src={addIcon} alt="" /> <span>Summarize</span>
                  </Button>
                  <div className="demo-file-container">
                    <img src={fileIcon} alt="no file" />
                    <div className="demo-sub-card-text">{demo?.firstText}</div>
                  </div>
                </div>
                <div className="demo-second-sub-card">
                  <Button className="demo-summarize-button">
                    <img src={addIcon} alt="" /> Summarize
                  </Button>
                  <div className="demo-file-container">
                    <img src={fileIcon} alt="no file" />
                    <div className="demo-sub-card-text">{demo?.secondText}</div>
                  </div>
                </div>
              </div>
              <div className="demo-slide-content">
                <h4 className="demo-slide-content-heading">{demo?.heading}</h4>
                <p className="demo-slide-content-description">
                  {demo?.description}
                </p>
                <div className="demo-slider-dots">
                  {data?.map((_, index) => (
                    <div
                      key={index}
                      className={`demo-slider-dot ${
                        currentSlide === index ? 'active' : ''
                      }`}
                      onClick={() => sliderRef.current.slickGoTo(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default DemoSlider;
