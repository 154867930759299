import React, { useState, useEffect } from 'react';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import MyCasesTable from './MyCasesTable/MyCasesTable';
import { useOutletContext } from 'react-router-dom';
import SearchSection from '../../Atticus/SearchSection/SearchSection';
import './CasesIndex.css';
import { advancedSearch } from '@lib/apis/pacer-api';
import casesData from '@lib/mocks/cases';

const CasesIndex = () => {
  const { setSelectedCase } = useOutletContext();
  const [cases, setCases] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await advancedSearch('');
      setCases(data);
    };
    // fetchData();
  }, []);

  const handleCaseSelect = (selectedCase) => {
    setSelectedCase(selectedCase);
  };
  return (
    <main className="dashboard-page cases-page">
      <PageTitle title="Federal Criminal" subtitle="Cases" />
      <SearchSection />
      <MyCasesTable cases={casesData}  onSelectCase={handleCaseSelect}  />
    </main>
  );
};

export default CasesIndex;
