import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import './Case.css';
import CollapsibleCard from '@Shared/CollapsibleCard/CollapsibleCard';
import { summarizeCase } from '@lib/apis/pacer-api';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { useSelector } from 'react-redux';
import { getAppId } from '@lib/utils/general';

const status = 'O';

const Case = () => {
  const { isRecommendationsFetched, selectedCase } = useOutletContext();
  const [caseSummary, setCaseSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const user = useSelector((state) => state.user);
  const {cognito_user_id: userId} = user;
  const appId = getAppId();

  const motionTypes = JSON.parse(localStorage.getItem('motionTypes'));
  const isFetched = localStorage.getItem('motionTypesFetched') ;

  useEffect(() => {
    const savedSummary = localStorage.getItem('caseSummary');
    if (savedSummary) {
      setCaseSummary(savedSummary);
    } else if (motionTypes && motionTypes.length > 0) {
      fetchCaseSummary();
    } else {
      setCaseSummary(null);
    }
  }, [isRecommendationsFetched]);

  const fetchCaseSummary = async () => {
    setLoading(true);
    try {
      const response = await summarizeCase(userId, selectedCase.caseId, appId);
      const summary = response?.data?.summary;
      if (summary) {
        localStorage.setItem('caseSummary', summary);
        setCaseSummary(summary);
      }
    } catch (error) {
      console.error('Failed to fetch case summary:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshCase = () => {
    setCaseSummary(null);
    setLoading(true);
    localStorage.removeItem('caseSummary');
    fetchCaseSummary();
  };

  const paragraphs = caseSummary ? caseSummary.split(/\n\n/) : [];
  const caseSummaryContent = loading || ! isRecommendationsFetched? (
    <p>
      <LoaderSpinner color="#2dc008" />
    </p>
  ) : (
    <div className="case-summary-description">
      {paragraphs.map((para, idx) => (
        <p key={idx}>{para}</p>
      ))}
    </div>
  );

  return (
    <>
      <CollapsibleCard
        title="Case Summary"
        content={caseSummaryContent}
        onRefresh={handleRefreshCase}
        isFetched={isFetched && !loading }
      />
      <div className="case-status-card white-background rounded-border">
        <div className="case-status-content-container">
          {' '}
          <div className="case-statis-label-text"> Case Status</div>{' '}
          <span
            className={`case-status ${
              status === 'O' ? 'open' : status === 'P' ? 'pending' : 'closed'
            }`}
          >
            {status === 'O' ? 'Open' : status === 'P' ? 'Pending' : 'Closed'}
          </span>
        </div>
        <div className="case-status-content-container">
          <div className="case-statis-label-text"> Case Filled </div>
          <div className="case-filled-date">Jan 10, 2024</div>{' '}
        </div>
      </div>
    </>
  );
};

export default Case;
