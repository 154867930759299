/* eslint-disable */

import axios from 'axios';
import cases from '../../mocks/cases'; // Assuming you have a mock data file for cases
import { getAuthHeaders, graphQlUrl } from '../utilities';
import { handleAddCaseApisFunc, updateCaseInDnmoDBCaseTable } from '@lib/apis/pacer-api';

const appId = process.env.REACT_APP_APP_ID || "1";

// CREATE
// TODO: Add graphQL queries to this function
export const createCase = async (userId, docketId, token = null) => {
  try {
    const addCaseResponse = await handleAddCaseApisFunc(userId, docketId);
    if (addCaseResponse) {
      const updateCaseInDnmoDBCaseTableResponse = await updateCaseInDnmoDBCaseTable(docketId);
      if (updateCaseInDnmoDBCaseTableResponse.success) {
        return {
          success: true,
          data: updateCaseInDnmoDBCaseTableResponse.data
        };
      } else {
        return {
          success: false,
          message: updateCaseInDnmoDBCaseTableResponse
        };
      }
    } else {
      return {
        success: false,
        message: addCaseResponse
      };
    }
  } catch (error) {
    console.error('Error creating case:', error);
    return {
      success: false,
      message: error.message
    };
  }


  // TODO: Replace the next line with the actual API call
  return Object.keys(params).length === 0 ? cases[0] : { ...params, caseId: 'new-case-id' }; // Mocked response

  // try {
  //   const response = await axios.post(`${BASE_URL}/cases`, params, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error creating case:', error);
  //   return error;
  // }
}

// READ
export const getCase = async (caseId, token = null) => {
  try {
    const query = `
    query MyQuery {
      case(appId: "${appId}", caseId: "${caseId}") {
        caseId
        appId
        docketNumber
        caseName
        courtId
        dateFiled
        dateLastFiling
        dateTerminated
        documentNumber
      }
    }
  `;

    const encodedQuery = encodeURIComponent(query);
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the case data

  } catch (error) {
    console.error('Error getting case:', error);
    return {
      success: false,
      message: error.message
    };
  }


  const id = 5028898
  return cases[0];
  // return id ? cases.find(c => c.caseId === id) : cases[0]; // Mocked response

  // try {
  //   const response = await axios.get(`${BASE_URL}/cases/${id}`, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error getting case:', error);
  //   return error;
  // }
}


export const getDocketEntriesForCase = async (caseId, token = null) => {

  try {
    const query = `
        query MyQuery {
          docketEntryByCase(caseId: "${caseId}") {
            docketEntryId
            caseId
            dateTime
            entryDetails
            classification
            dateCreated
            dateFiled
            description
            documentExists
            entryNumber
            pacerSequenceNumber
            resourceUri
             summaryS3Url
          }
        }
    `;
    const encodedQuery = encodeURIComponent(query);
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data.docketEntryByCase
    }; // Return the case data
  } catch (error) {
    console.error('Error getting docket entries for case:', error);
    return {
      success: false,
      message: error.message
    };
  }
}

// UPDATE
export const updateCase = async (id, params, token) => {
  return Object.keys(params).length === 0 ? cases[0] : { ...cases[0], ...params }; // Mocked response

  // try {
  //   const response = await axios.put(`${BASE_URL}/cases/${id}`, params, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error updating case:', error);
  //   return error;
  // }
}

// DELETE
export const deleteCase = async (id, token) => {
  return cases[0]; // Mocked response

  // try {
  //   const response = await axios.delete(`${BASE_URL}/cases/${id}`, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error deleting case:', error);
  //   return error;
  // }
}

// GET ALL FOR USER
export const getCases = async (params, token) => {
  console.log('getCases', cases);
  return cases; // Mocked response

  // try {
  //   const response = await axios.get(`${BASE_URL}/cases`, getAuthHeaders(token));
  //   const { cases } = response.data;
  //   return cases;
  // } catch (error) {
  //   console.error('Error getting cases:', error);
  //   return error;
  // }
}
