import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ButtonPrimary from '@Shared/ButtonPrimary/ButtonPrimary';
import JuristLogo from '../../../../../../assets/images/juristAi/jurist-logo.png';
import AtticusLogo from '@assets/images/atticus/logo/atticus-full-logo.svg';
import { ReactComponent as HomeIcon } from '@icons/home.svg';
import { ReactComponent as TeamLargeIcon } from '@icons/team-large.svg';
import { ReactComponent as CasesIcon } from '@icons/folder-open.svg';
import { ReactComponent as AccountIcon } from '@icons/profile.svg';
import { ReactComponent as SubscriptionsIcon } from '@icons/cash.svg';
import { ReactComponent as OrganizationIcon } from '@icons/team-small.svg';
import { ReactComponent as AskIcon } from '@icons/chat-bubble.svg';
import NavBarListItem from '../../../../../Shared/Layout/NavBarListItem/NavBarListItem';
import './NavBar.css';
import { ReactComponent as CaseOverviewIcon } from '@icons/home.svg';
import { ReactComponent as CaseTimelineIcon } from '@icons/team-large.svg';
import { ReactComponent as UploadDocumentIcon } from '@icons/folder-open.svg';

// TODO: Fix OrganizationIcon's bold stroke issue

const NavBar = ({ selectedCase }) => {
  const { id } = useParams();
  return (
    <div className="dashboard-navigation">
      <div className="dashboard-navigation-logo-container">
        <Link to={'https://juristai.org/'}>
          <img src={JuristLogo} className="dashboard-nav-logo" alt="logo" />
        </Link>
        <Link to='/dashboard'>
          <img src={AtticusLogo} className="dashboard-nav-logo" alt="logo" />
        </Link>
      </div>
      <ul className="dashboard-navigation-list-items">
        <NavBarListItem icon={HomeIcon} text="Home" url="/dashboard" />
        <NavBarListItem
          icon={TeamLargeIcon}
          text="Your Legal Team"
          url="/dashboard/team"
        />
        <NavBarListItem icon={CasesIcon} text="Cases" url="/dashboard/cases/" />
        <NavBarListItem
          icon={AccountIcon}
          text="Account Options"
          url="/dashboard/account/"
        />
        <li className="dashboard-navigation-special-link">
          <Link to="#">Select practice area</Link>
        </li>
        <NavBarListItem
          icon={SubscriptionsIcon}
          text="Manage Subscriptions"
          url="/dashboard/subscriptions/"
        />
        <NavBarListItem
          icon={OrganizationIcon}
          text="Manage Organization"
          url="/dashboard/organization/"
        />
        {selectedCase && (
          <>
            <h6 className="navigation-case-title">
              {selectedCase?.caseDetails?.caseName}
            </h6>
            <ul className="dashboard-navigation-list-items">
              <NavBarListItem
                icon={CaseOverviewIcon}
                text="Case Overview"
                url={`/dashboard/cases/${id}/overview`}
              />
              <NavBarListItem
                icon={CaseTimelineIcon}
                text="Case Timeline"
                url={`/dashboard/cases/${id}/timeline`}
              />
              <NavBarListItem
                icon={UploadDocumentIcon}
                text="Upload Documents"
                url={`/dashboard/cases/${id}/upload`}
              />
            </ul>
          </>
        )}
      </ul>
      <Link to={'/chat'}>
        <ButtonPrimary style={{ width: '90%' }}>
          <AskIcon />
          Ask Atticus
        </ButtonPrimary>
      </Link>
    </div>
  );
};

export default NavBar;
