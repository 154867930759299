import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// import JuristAiDashboard from '../components/Dashboard/JuristAi/Welcome/Welcome';
import AtticusDashboard from '@pages/Dashboard/Atticus/Atticus/Atticus';
import CasesIndex from '@pages/Dashboard/Atticus/Cases/CasesIndex/CasesIndex';
import CasesSearch from '@pages/Dashboard/Atticus/Cases/CasesSearch/CasesSearch';
import LegalTeamsIndex from '@pages/Dashboard/Atticus/LegalTeams/LegalTeamsIndex/LegalTeamsIndex';
import DashboardLayout from '@pages/Dashboard/Atticus/_Layout/Layout';
import Home from '@pages/Home/Home/Home';
import Layout from '@pages/Home/_Layout/Layout';
import LegalTeamMemberProfile from '@pages/Dashboard/Atticus/LegalTeams/LegalTeamsIndex/LegalTeamMemberProfile/LegalTeamMemberProfile';
import CaseOverview from '@pages/Dashboard/Atticus/Cases/CasesShow/CaseOverview/CaseOverview.jsx';
import UploadDocuments from '@pages/Dashboard/Atticus/Cases/CasesShow/UploadDocuments/UploadDocuments.jsx';
import CaseTimeline from '@pages/Dashboard/Atticus/Cases/CasesShow/CaseTimeline/CaseTimeline.jsx';
import ManageOrganization from '@pages/Dashboard/Atticus/ManageOrganization/ManageOrganization/ManageOrganization.jsx';
import ChatRoomLayout from '@pages/Dashboard/Chat/_Layout/Layout.jsx';
import Welcome from '@pages/Dashboard/Chat/Welcome/Welcome';
import Profile from '@pages/Dashboard/Chat/Workflow/Profile/Profile';
import Argument from '@pages/Dashboard/Chat/Workflow/Argument/Argument';
import Research from '@pages/Dashboard/Chat/Research/Research';
import Compare from '@pages/Dashboard/Chat/Workflow/Compare/Compare';
import Trial from '@pages/Dashboard/Chat/Workflow/Trial/Trial';
import Analyze from '@pages/Dashboard/Chat/Workflow/Analyze/Analyze.jsx';

import LogIn from '@pages/Auth/LogIn/LogIn';
import SignUp from '@pages/Auth/SignUp/SignUp';
import LogOut from '@pages/Auth/LogOut/LogOut';
import ConfirmAccount from '@pages/Auth/ConfirmAccount/ConfirmAccount';
import PageNotFound from '@pages/Auth/PageNotFound/PageNotFound';
import TestPage from '@pages/Home/Test/Test';
import DocketUpload from '@pages/Dashboard/Atticus/Cases/CasesShow/DocketUpload/DocketUpload';
import { useSelector } from 'react-redux';

function AllRouter() {
  const isAuthenticated = useSelector((state) => state.user);
  const PublicRoute = ({ children }) => {
    if (isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="logout" element={<LogOut />} />
          <Route
            path="register/confirm-account/:token"
            element={<ConfirmAccount />}
          />
        </Route>
        <Route
          path="login"
          element={
            <PublicRoute>
              <LogIn />
             </PublicRoute>
          }
        />
        <Route 
          path="signup"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route path="test_dev" element={<TestPage />} />
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route index element={<AtticusDashboard />} />
          <Route path="cases/">
            <Route index element={<CasesIndex />} />
            <Route path="search/" element={<CasesSearch />} />
            <Route path=":id/docketupload" element={<DocketUpload />} />
            <Route path=":id/overview" element={<CaseOverview />} />
            <Route path=":id/upload" element={<UploadDocuments />} />
            <Route path=":id/timeline" element={<CaseTimeline />} />
          </Route>
          <Route path="team/" element={<LegalTeamsIndex />} />
          <Route
            path="team-member/:userId"
            element={<LegalTeamMemberProfile />}
          />
          <Route path="organization" element={<ManageOrganization />} />
        </Route>

        <Route path="/chat/" element={<ChatRoomLayout />}>
          <Route index element={<Welcome />} />
          <Route path="profile" element={<Profile />} />
          <Route path="argument" element={<Argument />} />
          <Route path="research" element={<Research />} />
          <Route path="compare" element={<Compare />} />
          <Route path="trial" element={<Trial />} />
          <Route path="analyze" element={<Analyze />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default AllRouter;
