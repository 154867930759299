import React from 'react';
import Button from '@Shared/Button/Button';

// This is just a test page to test API calls with a button

const TestPage = () => {
  const handleClick = async () => {
    // Call the API function here
  };

  return (
    <div>
      <h1>Test Page</h1>
      <Button onClick={handleClick}>Test Button</Button>
    </div>
  );
};

export default TestPage;
