import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TimelineItem from './TimelineItem/TimelineItem';
import './CaseTimeline.css';
import Dropdown from '@Shared/Dropdown/Dropdown';
import arrowUp from '@icons/arrow-up-filled-black.svg';
import arrowDown from '@icons/arrow-down-filled-black.svg';
import cases from '@lib/mocks/cases';
import { useOutletContext } from 'react-router-dom';
import Loader from '@Shared/Loader/Loader';
import { getDocketEntriesForCase } from '@lib/apis/resources/case';
import { sortTimelineData } from '@lib/utils/general';

const FILTER_TYPES = ['Newest to Oldest', 'Oldest to Newest'];

const CaseTimeline = () => {
  const { id: caseId } = useParams();
  const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [timeLineData, setTimeLineData] = useState([]);
  const { selectedCase } = useOutletContext();

  useEffect(() => {
    const fetchTimeLineData = async () => {
      setLoading(true);
      try {
        const response = await getDocketEntriesForCase(caseId);
        if (response?.success == true && response?.data !== null) {
          const sortedData = sortTimelineData(response?.data, selectedFilter);
          setTimeLineData(sortedData);
          setLoading(false);
        }
      } catch (error) {
        console.log("CaseTimeLineError: ", error)
      } finally {
        setLoading(false);
      }
    };
    fetchTimeLineData();
  }, [caseId]);

  const handleFunctionSelect = (filterType) => {
    setSelectedFilter(filterType);
    setCurrentIndex(0);

    const sortedData = sortTimelineData(timeLineData, filterType);
    setTimeLineData(sortedData);
  };

  const handleNext = () => {
    if (currentIndex < timeLineData?.length - 3) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };


  return (
    <main className="case-timeline-page">
      <div className="case-timeline-page-dropdown">
        <div className="case-timeline-page-dropdown-content">
          <Dropdown
            options={FILTER_TYPES}
            selectedOption={selectedFilter}
            onOptionSelect={handleFunctionSelect}
            placeholder="Select Function"
            isFilter
          />
        </div>
      </div>
      {loading ? <Loader/> : 
      (
      <div className="timeline-container">
        {timeLineData?.length > 0 ? (
          <>
            <img
              src={arrowUp}
              alt="Arrow Up"
              className="case-timeline-filled-arrow-up"
              onClick={handlePrevious}
              style={{
                opacity: currentIndex > 0 ? 1 : 0.5,
                cursor: currentIndex > 0 ? 'pointer' : 'not-allowed',
              }}
            />
            <div className="timeline-line"></div>
            <div className="timeline-items">
              {timeLineData
                ?.slice(currentIndex, currentIndex + 3)
                ?.map((item, index) => (
                  <TimelineItem
                    key={index + currentIndex}
                    date={item?.dateFiled}
                    role={item?.classification}
                    documents={item?.description}
                    documentExists={item?.documentExists}
                    summaryS3Url={item.summaryS3Url}
                    docketEntryId={item.docketEntryId}
                    caseId={item.caseId}
                  />
                ))}
            </div>
            <img
              src={arrowDown}
              alt="Arrow Down"
              className="case-timeline-filled-arrow-down"
              onClick={handleNext}
              style={{
                opacity: currentIndex < timeLineData?.length - 3 ? 1 : 0.5,
                cursor:
                  currentIndex < timeLineData?.length - 3
                    ? 'pointer'
                    : 'not-allowed',
              }}
            />
          </>
        ) : (
          <div className="no-data-message">No timeline data available.</div>
        )}
      </div>
        )}
    </main>
  );
};

export default CaseTimeline;
