import { toast } from "react-toastify";
import { generateDocketRecommendations } from "../apis/pacer-api";

export const getOpenCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'open');
}

export const getClosedCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'closed');
}

export const titleToAbbreviation = (caseTitle) => {
  const words = caseTitle.trim().split(/\s+/);
  const firstLetters = words.map((word) => {
    if (word === 'v.s') {
      return 'vs';
    } else {
      return word.length <= 2 ? word : word[0];
    }
  });
  return firstLetters.filter(Boolean).join(' ');
}

/**
 * Retries an API call function multiple times until it succeeds or the retry limit is reached.
 * The first attempt will be made immediately, and subsequent attempts will have a 30-second delay.
 * 
 * @param {Function} callAPIFunction - The API function to be called (e.g., a function that makes an HTTP request).
 * @param {Array|*} [params=[]] - Parameters to pass to the API function. If only a single value is provided, it will be converted into an array.
 * @param {number} [retryLimit=Infinity] - The maximum number of retry attempts. Defaults to no limit (Infinity).
 * @param {boolean} [showToast=false] - A flag to control whether to display a toast notification upon failure after exhausting retry attempts.
 * @param {number} [delay=30000] - The delay (in milliseconds) between retry attempts after the first one. Default is 30000ms (30 seconds).
 * 
 * @returns {Object|null} - Returns the API response if successful, otherwise null if retry attempts are exhausted.
 */
export const retryApiCall = async (callAPIFunction, params = [], retryLimit = Infinity, showToast = false, delay = 30000) => {
  let response;
  let attempts = 0;

  if (!Array.isArray(params)) {
    params = [params];
  }

  const delayFunction = ms => new Promise(resolve => setTimeout(resolve, ms));

  do {
    attempts++;

    if (attempts > 1) {
      console.log(`Waiting ${delay / 1000} seconds before retrying...`);
      await delayFunction(delay); 
    }

    try {
    
      response = await callAPIFunction(...params);

      if (response?.success) {
        return response;
      } else {
        console.warn(`Attempt ${attempts} for ${callAPIFunction.name} failed.`);
      }
    } catch (error) {
      console.error(`Error during ${callAPIFunction.name} on attempt ${attempts}:`, error);
    }

    if (attempts >= retryLimit) {
      if (showToast) {
        toast.error('Unable to add a case. Please wait and try again. If this issue persists, please contact support at help@atticusai.org');
      }
      break;
    }

  } while (!response?.success);

  return null;
};

export const fetchMotion = async ({
  userId,
  caseId,
  appId,
  setLoading,
  setMotionTypes,
  setIsRecommendationsFetched,
  loadingRef
}) => {
try {
  if (loadingRef.current) return;
  loadingRef.current = true;
  setLoading(true);

  const response = await retryApiCall(generateDocketRecommendations, [userId, caseId, appId]);
  if (response?.success === true && response?.data?.length > 0) {
    const updatedMotionTypes = response?.data
      .map((motion) => ({
        name: motion?.name,
        value: motion?.value,
      }))
      .filter((motion) => motion?.name !== null);

    localStorage.setItem('motionTypes', JSON.stringify(updatedMotionTypes));
    localStorage.setItem('motionTypesFetched', 'true');
    setMotionTypes(updatedMotionTypes);
    setLoading(false);
    setIsRecommendationsFetched(true);
  }
} catch (error) {
  console.error('Error fetching motion types: ', error);
} finally {
  loadingRef.current = false;
}};