import React, { useState, useEffect, useRef } from 'react';
import './Motions.css';
import Button from '@Shared/Button/Button';
import MotionOptionsSelect from './MotionOptionsSelect';
import { ReactComponent as DownloadFile } from '@icons/download-file.svg';
import GenerateMotionModal from './GenerateMotionModal/GenerateMotionModal';
import GenerateMotionProvider from './GenerateMotionProvider';
import GenerateMotionButton from './GenerateMotionButton';
import MotionTypeButtons from './MotionTypeButtons';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { getAppId } from '@lib/utils/general';
import { useSelector } from 'react-redux';
import { fetchMotion } from '@lib/utils/caseHelpers';

const Motions = ({ setIsRecommendationsFetched }) => {
  const [motionTypes, setMotionTypes] = useState([]);
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const recentMotions = [{ name: 'Motion to Extend Time' }, { name: 'Motion to Suppress Evidence' }];

  const user = useSelector((state) => state.user);
  const { cognito_user_id: userId } = user;
  const caseId = id;
  const appId = getAppId();

  useEffect(() => {
    const savedMotions = JSON.parse(localStorage.getItem('motionTypes'));
    const isFetched = localStorage.getItem('motionTypesFetched');

    if (savedMotions && savedMotions.length > 0) {
      setMotionTypes(savedMotions);
      setLoading(false);
    } else if (!isFetched) {
      fetchMotion({
        userId,
        caseId,
        appId,
        setLoading,
        setMotionTypes,
        setIsRecommendationsFetched,
        loadingRef,
      });
    }
  }, [userId, caseId, appId]);

  return (
    <GenerateMotionProvider>
      <div className="motion-container">
        <h3 className="cases-motion-title">Motions</h3>
        <div className="motion-recommendations-container">
          <h4>Atticus Recommends</h4>
          {loading ? (
            <>
              <LoaderSpinner color="#2dc008" />
            </>
          ) : (
            <>
              <MotionTypeButtons motionTypes={motionTypes} />
              <MotionOptionsSelect />
              <GenerateMotionButton />
            </>
          )}
        </div>
        <div className="recent-motions-container">
          <h4>Recently Used Motions</h4>
          {recentMotions.map((motion, index) => (
            <div className="recent-motion" key={index}>
              <DownloadFile /> {motion.name}
            </div>
          ))}
        </div>
        <GenerateMotionModal />
      </div>
    </GenerateMotionProvider>
  );
};

export default Motions;
